export const environment = {
    baseURL: 'https://api-prod.crizac.com/v1/api/',
    fileBaseURL: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system',
    callBaseURL: 'https://www.crizac.com/yeasterdownload/',
    validatePassportURL: 'https://nps2entus1.execute-api.ap-south-1.amazonaws.com/default/ocr',
    currentLocationURL: 'https://ipapi.co/json/',
    basePHPURL: 'https://accounts.crizac.com/api_mongo/agent',
    paypalClientId: 'AeEifrmeT8xMflSOSZf6jFQMGMAVarQI_3LAnoPMgjQfITNyl1bBTBSUdYUyXjgysRc0Klp7Cgi71ZIu',
    apiPHPURLv2: 'https://accounts.crizac.com/api_mongo/',    
    paymentPercentage: 10,
    tenant: "3",
    socketURL:'wss://api.crizac.com',
    mailDomain: 'gasolutions.in',
    notificationApi: "wss://api-console.crizac.com",
    chatApi: "wss://api-console.crizac.com/support-chat-notification",
    useApplicationPaymentFlow: true,
    apiPHPURLv3: 'https://accounts.crizac.com/',
    worldPaymerchantId: 'PO4058892922',
    APP_VERSION: "0.0.19"
 
};
